import { createSlice } from '@reduxjs/toolkit';
import { stocktakeitemsApi } from '../../services/stocktakeitems';
import { DEFAULT_PER_PAGE } from '../../common/constants';
import { stocktakeApi } from '../../services/stocktake';

const initialState = {
  scanned_items_pagination: {
    links: [],
    last_page: 0,
    per_page: DEFAULT_PER_PAGE,
    current_page: 1,
  },
  scanned_items: {},
  inventory_view: [],
  last_scanned: {
    barcode: '',
    count: 0,
  },
  filters: {
    is_existing: 0,
    search: '',
  }
};

export const stocktakeSlice = createSlice({
  name: 'stocktake',
  initialState,
  reducers: {
    scanItem: (state, action) => {
      const barcode = action.payload.barcode;
      const item = { ...action.payload, barcode };

      state.scanned_items[barcode] = item;
      state.last_scanned = { barcode, ...item, };
    },
    setItemCount: (state, action) => {
      const { barcode, quantity, title } = action.payload;
      const item = { ...(state.scanned_items[barcode] ?? {}), quantity, title };

      state.scanned_items[barcode] = item;
    },
    setScannedItemsPagination: (state, action) => {
      state.scanned_items_pagination.current_page = action.payload.current_page;
      state.scanned_items_pagination.per_page = action.payload.per_page;
    },
    setFilter: (state, action) => {
      state.filters[action.payload.status] = action.payload.value;
      state.scanned_items_pagination.current_page = 1;
    },
    addQuantity: (state, action) => {
      const barcode = action.payload;
      state.scanned_items[barcode].quantity++;
    },
    subtractQuantity: (state, action) => {
      const barcode = action.payload;
      state.scanned_items[barcode].quantity--;
    },
    deleteItem: (state, action) => {
      const barcode = action.payload;
      delete state.scanned_items[barcode];
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      stocktakeitemsApi.endpoints.stocktakeItems.matchFulfilled,
      (state, action) => {
        const {
          data,
          links,
          last_page,
          per_page,
          current_page,
        } = action.payload;

        state.scanned_items_pagination = {
          links: links,
          last_page: last_page,
          per_page: per_page,
          current_page: current_page,
        }

        state.scanned_items = {};

        data.map(item => {
          let d = {
            barcode: item.generic_sku,
            generic_sku: item.generic_sku,
            quantity: item.quantity,
            on_hand_quantity: item.on_hand_quantity,
            title: item.title,
            is_existing: item.is_existing,
            stock_take_item_id: item.id,
          };
          
          state.scanned_items[item.generic_sku] = d;

          return d;
        });
      }
    );
  }
});

// Action creators are generated for each case reducer function
export const { scanItem, setItemCount, setScannedItemsPagination, setFilter, addQuantity, subtractQuantity, deleteItem } = stocktakeSlice.actions;

export default stocktakeSlice.reducer;
