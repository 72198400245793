import { useSelector } from "react-redux";
import { Button, ButtonGroup, Col, Row } from "reactstrap";
import SimpleBar from 'simplebar-react';
import { genericSKUBreakdown, getConditionNameBySymbol } from "../../../../common/helpers/product";
import { useConditionsQuery } from "../../../../services/condition";
import ProductTitle from "../../../../Components/Common/ProductTitle";
import { useEffect, useState } from 'react';
import { DEFAULT_PER_PAGE } from '../../../../common/constants';
import { useParams } from "react-router-dom";
import BPagination from '../../../../Components/Common/BPagination';

import {
  useUpdateMutation,
  useDeleteStockTakeItemMutation
} from '../../../../services/stocktakeitems';
import BDropdown from "../../../../Components/Common/BDropdown";
import { setFilter, addQuantity, subtractQuantity, deleteItem } from "../../../../store/slice/stocktake";
import { useDispatch } from "react-redux";
import { useAsyncDebounce } from "react-table";
// import { debounce } from '../../../utils/debounce';

const ScannedItems = props => {
  const dispatch = useDispatch();
  const { data: conditions_data } = useConditionsQuery({ per_page: 1000 });
  const stocktake = useSelector(store => store.stocktake);

  // const scanned_items = stocktake.scanned_items;  
  // const scanned_items_keys = Object.keys(scanned_items);

  const [status_filter, setStatusFilter] = useState({ value: 0, label: 'All' });
  const [search, setSearch] = useState(null);
 
  const [scanned_items, setScannedItems] = useState({});
  const [scanned_items_keys, setScannedItemsKeys] = useState([]);
  const [type, setType] = useState();

  const [updateItem, { isLoading: is_updating }] = useUpdateMutation();
  const [deleteStockTakeItem] = useDeleteStockTakeItemMutation();

  // Update local state when Redux state changes
  useEffect(() => {
    if (!!scanned_items) {
      let st_keys = Object.keys(stocktake.scanned_items);

      setScannedItems(stocktake.scanned_items);
      setScannedItemsKeys(st_keys);
    }
  }, [scanned_items, stocktake.scanned_items]);

  useEffect(() => {
    setStatusFilter({ value: stocktake.filters.is_existing, label: ['All', 'Scanned', 'Not Found'][stocktake.filters.is_existing ?? 0] });
  }, [stocktake.filters.is_existing])

  const handleSearch = () => {
    dispatch(setFilter({ status: 'search', value: search }));
  };
  const handleSetStatusFilter = (status) => {
    setStatusFilter(status);
    let value = 0
    if (status.value === 0) {
      value = undefined;
    } else if (status.value === 1) {
      value = 1;
    }

    dispatch(setFilter({ status: 'is_existing', value }));
  }
  const handleAddCount = (itemKey) => {
    const xhr_data = { id: scanned_items[itemKey].stock_take_item_id, quantity: +(scanned_items[itemKey].quantity) + 1, generic_sku: itemKey };
    debouncedHandleUpdateRow(xhr_data);
    dispatch(addQuantity(itemKey));
  };
  const handleSubtractCount = (itemKey) => {
    if (scanned_items[itemKey].quantity > 0) {
      const xhr_data = { id: scanned_items[itemKey].stock_take_item_id, quantity: +(scanned_items[itemKey].quantity) - 1, generic_sku: itemKey };
      debouncedHandleUpdateRow(xhr_data);
      dispatch(subtractQuantity(itemKey));
    }
  };
  const handleUpdateRow = async (params) => {
    try {
      // console.log(scanned_items[itemKey]);
      const xhr = await updateItem(params);
      if (xhr?.error) {
        throw new Error(xhr.error.data.message);
      }
      
    } catch (err) {
      // console.log('Error updating item');
    }
  }
  const handleDeleteScannedItem = (itemKey) => {
    handleDeleteItem(itemKey);
    dispatch(deleteItem(itemKey));
  };

  const handleDeleteItem = async (itemKey) => {
    try {
      const xhr_data = { id: scanned_items[itemKey].stock_take_item_id };
      const xhr = await deleteStockTakeItem(xhr_data);
      if (xhr?.error) {
        throw new Error(xhr.error.data.message);
      }
    } catch (err) {
      console.log(err);
      console.log('Error deleting item');
    }
  };

  const debouncedHandleUpdateRow = useAsyncDebounce(handleUpdateRow, 1000);

  return (
    <Row>
      <SimpleBar style={{ maxHeight: '500px' }}>
        <Col lg={12}>
          <div className="col-sm" style={{ padding: '10px' }}>
            <div className="d-flex justify-content-sm-end">
              <div className="ms-2">
                  <BDropdown
                    value={status_filter}
                    handleSetValue={handleSetStatusFilter}
                    options={[
                      { value: 0, label: 'All' },
                      { value: 1, label: 'Scanned' },
                      { value: 2, label: 'Not Found' }
                    ]}
                  />
              </div>
              <div className="search-box ms-2 col-md-3 ">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearch();
                  }}
                >
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setSearch(e.target.value || undefined);
                    }}
                    placeholder="Search by Title or Generic SKU"
                    value={search || ''}
                  />
                  <i className="ri-search-line search-icon"></i>
                </form>
              </div>
              <div className="d-flex flex-wrap align-items-center gap-2 ms-2">
                <Button
                  color="secondary"
                  outline
                  onClick={handleSearch}
                  className="custom-toggle active"
                >
                  <span className="icon-on">
                    <i className="ri-search-line search-icon align-bottom me-1"></i>
                    Search
                  </span>
                </Button>
              </div>
            </div>
          </div>

          <table
            className="table align-middle table-nowrap table-striped-columns"
            id="table_stocktake"
          >
            <thead className="table-light bg-light sticky-top">
              <tr>
                <th scope="col" style={{ width: '23 %' }}>
                  Product
                </th>
                <th
                  scope="col"
                >
                  Size
                </th>
                <th scope="col">Condition</th>
                <th scope="col">Count</th>
                <th scope="col" ></th>
              </tr>
            </thead>
            <tbody>
              {
                (scanned_items_keys || []).filter(item => {
                  if (status_filter.value === 1) {
                    return scanned_items[item].is_existing;
                  } else if (status_filter.value === 2) {
                    return !scanned_items[item].is_existing;
                  }
                  return true;
                }).map(item => {
                  const gsku = genericSKUBreakdown(item);
                  return (
                    <tr key={item}>
                      <td>
                        <div className="d-flex justify-content-between">
                          <ProductTitle
                            generic_sku={item}
                            title={scanned_items[item].title || ''}
                            style={{ maxWidth: '350px' }}
                          />

                          {(!scanned_items[item].is_existing) && <i className="ri-error-warning-line text-warning"></i>}
                        </div>
                      </td>
                      <td>{gsku.size ?? '-'}</td>
                      <td>{getConditionNameBySymbol(gsku.condition, conditions_data?.data) || '-'}</td>
                      <td>{scanned_items[item].quantity ?? '-'}</td>
                      <td className="text-center" >
                        <ButtonGroup size="sm">
                          <Button color="primary" className="btn-icon" outline><i className="ri-subtract-line" onClick={() => handleSubtractCount(item)} /></Button>
                          <Button color="primary" className="btn-icon" outline><i className="ri-add-line" onClick={() => handleAddCount(item)} /></Button>
                        </ButtonGroup>
                        <Button size="sm" color="danger" className="ms-1 btn-icon" onClick={()=> handleDeleteScannedItem(item)}><i className="ri-delete-bin-line" /></Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </Col>
      </SimpleBar>
    </Row>
  );
}

export default ScannedItems;